// @ts-nocheck
import intl from 'react-intl-universal';

export const getZatcaMode = () => [
  { 
    value: 'sandbox', 
    name: `${intl.get('zatca_mode_sandbox')}` 
  },
  { 
    value: 'production', 
    name: `${intl.get('zatca_mode_production')}` 
  }
]