// @ts-nocheck
import React, { useContext } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import TableContext from './TableContext';

export default function TableTBody({ children }) {
  const {
    table: { getTableBodyProps },
    props = {},
  } = useContext(TableContext);

  return (
    <ScrollSyncPane>
      <div
        {...getTableBodyProps()}
        className={`tbody scrollbar-custom-track`}
        style={props.tBodyStyle ?? {}}
      >
        <div class="tbody-inner">{children}</div>
      </div>
    </ScrollSyncPane>
  );
}
